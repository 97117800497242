import { get, post, del } from './request';
import moment from 'moment';
// import ReportJsonData from '@/json/report-mock.json';
// import reportMockParkingData from '@/json/report-mock-parking.json';
// import reportMockActualParking from '@/json/report-mock-actual-parking.json';
// import reportMockActualCamera from '@/json/report-mock-actual-camera.json';
// import reportMockCop from '@/json/report-mock-cop-data.json';
// import reportMockProfession from '@/json/report-mock-profession.json';
// import reportRunningTimeDHCJsonData from '@/json/report-mock-running-time-profession.json';
// import reportMockRealtimeDeviceProfessionJsonData from '@/json/report-mock-real-time-device-profession.json';
import { SimpleTreeModel } from '@/model/simple-tree-model';
// import { DeviceFloorCountModel, DeviceAreaCountModel, HourCOPDHCModel, RealtimeDeviceHVACCountModel, RunningTimeHVACCountModel, ActualFailureHVACModel, RunningTimeDHCCountModel, ActualFailureDHCModel, FloorDeviceCountQueryModel, DailyPassagerCountQueryModel, RunningTimeCountModel, CurrentDayCountQueryModel, AreasSplitTimeParkingBaseQueryModel, FloorSplitTimeParkingBaseQueryModel } from '@/model/report-query';

/**
 * 转换图表数据
 * @param resData api返回的数据
 * @param columns 表格的列(code name)
 */
export function timeTransformData(resData: any, columns: Array<{code: string, name: string}>, dateFormat: string) {
    const dataRows = [];
    const dimension = columns[0].code;
    const dataColumns = [dimension];

    _.forEach(resData, item => {
        for (let index = 1; index < columns.length; index++) {
            dataColumns.push(`${item.name}${columns[index].name}`);
        }
    });

    _.forEach(resData, item => {
        _.forEach(item.flowData, fd => {
            let dataItem: any = {};
            dataItem[dimension] = moment(fd[dimension]).format(dateFormat);
            for (let index = 1; index < columns.length; index++) {
                let fdValue = null;
                // 百分比保留4位小数
                if (columns[index].code === 'rate') {
                    try {
                        fdValue = fd[columns[index].code].toFixed(4);
                    } catch (error) {
                        $log.warn(error);
                    }
                } else {
                    fdValue = fd[columns[index].code];
                }
                dataItem[`${item.name}${columns[index].name}`] = fdValue;
            }
            let findData = _.find(dataRows, dr => dr[dimension] === dataItem[dimension]);
            if (findData) {
                findData = Object.assign(findData, dataItem);
            } else {
                dataRows.push(dataItem);
            }
        });
    });

    return {
        columns: dataColumns,
        rows: dataRows
    };
}

export const URL_PATH = `${BIZ_BASE_REQUEST_PATH}/report`;

export const REPORT_URL_PATH = `${BIZ_BASE_REQUEST_PATH}/report`;

// function getReportCOPJsonData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(reportMockCop.items);
//         }, 2000);
//     });
// }
// function getReportJsonData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(ReportJsonData.items);
//         }, 2000);
//     });
// }
// function getReportMockParkingData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(reportMockActualParking.items);
//         }, 2000);
//     });
// }
// function getReportFailuerDHCJsonData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(reportMockProfession.items);
//         }, 2000);
//     });
// }
// function getReportRunningTimeDHCJsonData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(reportRunningTimeDHCJsonData.items);
//         }, 2000);
//     });
// }
// function getReportRealtimeDeviceProfessionJsonData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(reportMockRealtimeDeviceProfessionJsonData.items);
//         }, 2000);
//     });
// }
// function getReportMockActualParkingData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(reportMockParkingData.items);
//         }, 2000);
//     });
// }
// function getReportMockActualCameraData() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(reportMockActualCamera.items);
//         }, 2000);
//     });
// }

const DEFAULT_EXTEND = {
    legend: {
        right: '20'
        // textStyle: {
        //     color: '#333'
        // }
    },
    series: {
        label: { show: false, position: 'inside' }
    }
};

export function assignDefaultExtend(chartOptions: any) {
    chartOptions.extend = Object.assign({}, DEFAULT_EXTEND, chartOptions.extend);
    return chartOptions;
}

class ReportService {
    async getLocationTree(type:any):Promise<any> {
        const url = `${URL_PATH}/${type}/groupList`;
        const res = await get(url);

        _.forEach(res, item => {
            item.selectable = true;
            item.id = `${item.parentId}|${item.id}`;
            _.forEach(item.children, item1 => {
                item1.id = `${item1.parentId}|${item1.id}`;
                item1.selectable = true;
            });
        });

        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async getLocationList(type:any):Promise<any> {
        const url = `${URL_PATH}/${type}/groupList`;
        const res = await get(url);
        return _.map(res, item => {
            return { value: item.id, name: item.name };
        });
    }

    async getAttributeList(type:any): Promise<any> {
        const url = `${URL_PATH}/${type}/attributeList`;
        const res = await get(url);
        const options = _.map(res, item => {
            return {
                name: item.desc,
                value: item.code
            };
        });
        return options;
    }
    // async getReportData(model:DailyPassagerCountQueryModel):Promise<DailyPassagerCountQueryModel> {
    //     const url = `${URL_PATH}/customerFlow/trend/daily`;
    //     const params = model.toService();
    //     const res = await post(url, params);
    //     const chartOptions = {
    //         data: timeTransformData(res, [{ code: 'timestamp', name: '时间' }, { code: 'inCount', name: '进客流' }, { code: 'outCount', name: '出客流' }], 'MM-DD'),
    //         settings: {
    //             type: 'line'
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getHourlyReportData(model:DailyPassagerCountQueryModel):Promise<DailyPassagerCountQueryModel> {
    //     const url = `${URL_PATH}/customerFlow/trend/hourly`;
    //     const params = model.toService();
    //     const res = await post(url, params);
    //     const chartOptions = {
    //         data: timeTransformData(res, [{ code: 'timestamp', name: '时间' }, { code: 'inCount', name: '进客流' }, { code: 'outCount', name: '出客流' }], 'MM-DD hh:mm'),
    //         settings: {
    //             type: 'line'
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getCurrentDayReportData(model:CurrentDayCountQueryModel):Promise<CurrentDayCountQueryModel> {
    //     const url = `${URL_PATH}/customerFlow/trend/daily`;
    //     const params = model.toService();
    //     // const rows:any = await post(url, params);
    //     // const chartOptions = {
    //     //     data: {
    //     //         columns: ['name', 'inCount'],
    //     //         rows
    //     //     },
    //     //     settings: {
    //     //         type: 'ring',
    //     //         radius: [50, 250],
    //     //         offsetY: '50%',
    //     //         roseType: 'radius',
    //     //         itemStyle: {
    //     //             borderRadius: 0
    //     //         }
    //     //     }
    //     // };
    //     const res = await post(url, params);
    //     const chartOptions = {
    //         data: timeTransformData(res, [{ code: 'timestamp', name: '时间' }, { code: 'inCount', name: '进客流' }, { code: 'outCount', name: '出客流' }], 'MM-DD'),
    //         settings: {
    //             type: 'line'
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }

    // async getParkingAreaHourLineReportData(model:AreasSplitTimeParkingBaseQueryModel):Promise<any> {
    //     // todo
    //     // const url = `${URL_PATH}/parking/trend`;
    //     // const params = model.toService();
    //     // const rows:any = await post(url, params);
    //     const rows: any = await getReportMockParkingData();
    //     _.map(rows, item => { _.assign(item, item.flowData[0]); });
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'rate', 'usingCount', 'freeCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'line',
    //             metrics: ['rate', 'usingCount', 'freeCount'],
    //             dimension: ['name'],
    //             labelMap: {
    //                 rate: '占有率',
    //                 usingCount: '占用车位',
    //                 freeCount: '空闲车位'
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }

    // async getParkingFloorHourLineReportData(model:FloorSplitTimeParkingBaseQueryModel):Promise<any> {
    //     // todo
    //     // const url = `${URL_PATH}/parking/trend`;
    //     // const params = model.toService();
    //     // const rows:any = await post(url, params);
    //     const rows: any = await getReportMockParkingData();
    //     _.map(rows, item => { _.assign(item, item.flowData[0]); });
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'rate', 'usingCount', 'freeCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'line',
    //             metrics: ['rate', 'usingCount', 'freeCount'],
    //             dimension: ['name'],
    //             labelMap: {
    //                 rate: '占有率',
    //                 usingCount: '占用车位',
    //                 freeCount: '空闲车位'
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getActualParkingBarReportData(): Promise<any> {
    //     // todo
    //     // const url = `${URL_PATH}/parking/count`;
    //     // const params = model.toService();
    //     // const rows:any = await post(url, params);
    //     const rows: any = await getReportMockParkingData();
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'freeCount', 'usingCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'histogram',
    //             // metrics: ['剩余车位', '已占用车位'],
    //             // dimension: ['name'],
    //             showLine: ['inCount'],
    //             labelMap: {
    //                 freeCount: '剩余车位',
    //                 usingCount: '已占用车位'
    //             }
    //         },
    //         extend: {
    //             yAxis: {
    //                 name: '单位：个',
    //                 position: 'left',
    //                 axisLine: {
    //                     show: true
    //                 },
    //                 splitLine: {
    //                     lineStyle: {
    //                         type: 'dashed'
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getDeviceAreaCameraBarReportData(model:DeviceAreaCountModel):Promise<any> {
    //     const url = `${URL_PATH}/camera/count`;
    //     const params = model.toService();
    //     const rows:any = await post(url, params);
    //     // const rows: any = await getReportMockActualCameraData();

    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'offlineCount', 'onlineCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'histogram',
    //             // metrics: ['剩余车位', '已占用车位'],
    //             // dimension: ['name'],
    //             showLine: ['inCount'],
    //             labelMap: {
    //                 offlineCount: '问题设备',
    //                 onlineCount: '在线设备'
    //             }
    //         },
    //         extend: {
    //             yAxis: {
    //                 name: '单位：个',
    //                 position: 'left',
    //                 axisLine: {
    //                     show: true
    //                 },
    //                 splitLine: {
    //                     lineStyle: {
    //                         type: 'dashed'
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }

    // async getDeviceFloorCameraBarReportData(model:DeviceFloorCountModel):Promise<any> {
    //     const url = `${URL_PATH}/camera/count`;
    //     const params = model.toService();
    //     const rows:any = await post(url, params);
    //     // const rows: any = await getReportMockActualCameraData();

    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'offlineCount', 'onlineCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'histogram',
    //             // metrics: ['剩余车位', '已占用车位'],
    //             // dimension: ['name'],
    //             showLine: ['inCount'],
    //             labelMap: {
    //                 offlineCount: '问题设备',
    //                 onlineCount: '在线设备'
    //             }
    //         },
    //         extend: {
    //             yAxis: {
    //                 name: '单位：个',
    //                 position: 'left',
    //                 axisLine: {
    //                     show: true
    //                 },
    //                 splitLine: {
    //                     lineStyle: {
    //                         type: 'dashed'
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getHourCOPDHCReportData(model:HourCOPDHCModel):Promise<any> {
    //     // todo
    //     const url = `${URL_PATH}/equipment/dhc/cop/certainDate`;
    //     const params = model.toService();
    //     const rows:any = await post(url, params);
    //     // const rows: any = await getReportCOPJsonData();

    //     const chartOptions = {
    //         data: {
    //             columns: ['timestamp', 'todayCapacity', 'yesterdayCapacity', 'ratio'],
    //             rows: rows.data
    //         },
    //         settings: {
    //             type: 'line',
    //             showLine: ['todayCapacity', 'yesterdayCapacity', 'ratio'],
    //             labelMap: {
    //                 todayCapacity: '当天',
    //                 yesterdayCapacity: '昨天',
    //                 ratio: '环比'
    //             },
    //             axisSite: { right: ['ratio'] }
    //         },
    //         extend: {
    //             yAxis: [
    //                 {
    //                     name: '单位：台',
    //                     position: 'left',
    //                     axisLine: {
    //                         show: true
    //                     },
    //                     splitLine: {
    //                         lineStyle: {
    //                             type: 'dashed'
    //                         }
    //                     }
    //                 },
    //                 {
    //                     name: '单位：台',
    //                     position: 'right',
    //                     axisLine: {
    //                         show: true
    //                     },
    //                     splitLine: {
    //                         show: false
    //                     }
    //                 }
    //             ]
    //             // yAxis(item) {
    //             //     /* 左轴 */
    //             //     // 坐标轴名称的文字样式
    //             //     item[0].nameTextStyle = {
    //             //         padding: [0, 10, 0, 0]
    //             //     };
    //             //     // 坐标轴线设置
    //             //     item[0]['axisLine'] = {
    //             //         show: true,
    //             //         lineStyle: {
    //             //             color: '#ccc'
    //             //         }
    //             //     };
    //             //     /* 右轴 */
    //             //     // 坐标轴名称的文字样式
    //             //     item[1].nameTextStyle = {
    //             //         padding: [0, 10, 0, 0]
    //             //     };
    //             //     // 坐标轴线设置
    //             //     item[1].axisLine = {
    //             //         show: true,
    //             //         lineStyle: {
    //             //             color: '#ccc'
    //             //         }

    //             //     };
    //             //     item[1].splitLine = {
    //             //         show: false
    //             //     };
    //             //     return item;
    //             // }

    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getActualFailureDHCReportData(model:ActualFailureDHCModel):Promise<any> {
    //     const url = `${URL_PATH}/equipment/dhc/failureCount`;
    //     const params = model.toService();
    //     const rows:any = await post(url, params);
    //     // const rows: any = await getReportFailuerDHCJsonData();
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'failureCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'histogram',
    //             // metrics: ['inCount', 'outCount'],
    //             // dimension: ['name'],
    //             showLine: ['inCount'],
    //             labelMap: {
    //                 failureCount: '损坏设备'
    //             }
    //         },
    //         extend: {
    //             yAxis: {
    //                 name: '单位：台',
    //                 position: 'left',
    //                 axisLine: {
    //                     show: true
    //                 },
    //                 splitLine: {
    //                     lineStyle: {
    //                         type: 'dashed'
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getRunningTimeDHCCountReportData(model:RunningTimeDHCCountModel):Promise<any> {
    //     const url = `${URL_PATH}/equipment/dhc/runningTime`;
    //     const params = model.toService();
    //     const rows:any = await post(url, params);
    //     // const rows: any = await getReportRunningTimeDHCJsonData();
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'maxTime', 'averageTime', 'deviationTime'],
    //             rows
    //         },
    //         settings: {
    //             type: 'histogram',
    //             // metrics: ['inCount', 'outCount'],
    //             // dimension: ['name'],
    //             showLine: ['deviationTime'],
    //             labelMap: {
    //                 maxTime: '最高运行时长',
    //                 averageTime: '平均时长',
    //                 deviationTime: '偏差值'
    //             }
    //         },
    //         extend: {
    //             yAxis: {
    //                 name: '单位：时长',
    //                 position: 'left',
    //                 axisLine: {
    //                     show: true
    //                 },
    //                 splitLine: {
    //                     lineStyle: {
    //                         type: 'dashed'
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getActualFailureHVACCountReportData(model:ActualFailureHVACModel):Promise<any> {
    //     const url = `${URL_PATH}/equipment/hvac/failureCount`;
    //     const params = model.toService();
    //     const rows:any = await post(url, params);
    //     // const rows: any = await getReportFailuerDHCJsonData();
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'failureCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'histogram',
    //             showLine: ['inCount'],
    //             labelMap: {
    //                 failureCount: '损坏设备'
    //             }
    //         },
    //         extend: {
    //             yAxis: {
    //                 name: '单位：台',
    //                 position: 'left',
    //                 axisLine: {
    //                     show: true
    //                 },
    //                 splitLine: {
    //                     lineStyle: {
    //                         type: 'dashed'
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getLineReportMockData(): Promise<any> {
    //     const rows: any = await getReportJsonData();
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'inCount', 'outCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'line',
    //             // metrics: ['inCount', 'outCount'],
    //             // dimension: ['name'],
    //             labelMap: {
    //                 inCount: '进客流',
    //                 outCount: '出客流'
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }

    // async getBarReportMockData(): Promise<any> {
    //     const rows: any = await getReportJsonData();
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'inCount', 'outCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'histogram',
    //             // metrics: ['inCount', 'outCount'],
    //             // dimension: ['name'],
    //             showLine: ['inCount'],
    //             labelMap: {
    //                 inCount: '进客流',
    //                 outCount: '出客流'
    //             }
    //         },
    //         extend: {
    //             yAxis: {
    //                 name: '单位：人次',
    //                 position: 'left',
    //                 axisLine: {
    //                     show: true
    //                 },
    //                 splitLine: {
    //                     lineStyle: {
    //                         type: 'dashed'
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getRunningTimeHVACCountReportData(model:RunningTimeHVACCountModel):Promise<any> {
    //     // todo
    //     const url = `${URL_PATH}/equipment/hvac/runningTime`;
    //     const params = model.toService();
    //     const rows:any = await post(url, params);
    //     // const rows: any = await getReportRunningTimeDHCJsonData();
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'maxTime', 'averageTime', 'deviationTime'],
    //             rows
    //         },
    //         settings: {
    //             type: 'histogram',
    //             showLine: ['deviationTime'],
    //             labelMap: {
    //                 maxTime: '最高运行时长',
    //                 averageTime: '平均时长',
    //                 deviationTime: '偏差值'
    //             }
    //         },
    //         extend: {
    //             yAxis: {
    //                 name: '单位：时长',
    //                 position: 'left',
    //                 axisLine: {
    //                     show: true
    //                 },
    //                 splitLine: {
    //                     lineStyle: {
    //                         type: 'dashed'
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
    // async getRealTimeDeviceHVACCountReportData(model: RealtimeDeviceHVACCountModel):Promise<any> {
    //     const url = `${URL_PATH}/equipment/hvac/runningCount`;
    //     const params = model.toService();
    //     const rows:any = await post(url, params);
    //     // const rows: any = await getReportRealtimeDeviceProfessionJsonData();
    //     const chartOptions = {
    //         data: {
    //             columns: ['name', 'stopCount'],
    //             rows
    //         },
    //         settings: {
    //             type: 'ring',
    //             radius: ['25%', '70%'],
    //             offsetY: '50%',
    //             roseType: 'radius',
    //             itemStyle: {
    //                 borderRadius: 0
    //             }
    //         }
    //     };
    //     return assignDefaultExtend(chartOptions);
    // }
}

export default new ReportService();
